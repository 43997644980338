body,
html,
* {
}

.bg-wecest-pink {
  background-color: #9024b2;
}
.text-wecest-pink {
  color: #9024b2;
}
.bg-wecest-blue {
  background-color: #63bdff;
}
.bg-audotask-gold {
  background-color: #ffecb7;
}
.text-light-gray {
  color: #464a5f;
}
.text-gray1 {
  color: #858585;
}

.text-gray2 {
  color: #808080;
}
.orange1 {
  background-color: #ffe976;
}
.orange2 {
  background-color: #ffe600;
}

.button-type-1 {
  background-color: #eae0e0;
  display: block;
  max-width: 230px;
  width: 100%;
  text-align: center;
  color: black;
  padding: 13px;
  font-weight: 700;
  border-radius: 10px;
  -moz-box-shadow: 0px 4px #282828;
  -webkit-box-shadow: 0px 4px #282828;
  box-shadow: 0px 4px #505050;
}

.button-type-2 {
  background-color: #63bdff;
  display: block;
  text-align: center;
  color: #ffffff;
  padding: 13px;
  font-weight: 700;
  border-radius: 2px;
}

PhoneInput {
  padding: 100px;
}

.sidebar-transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

option {
  background-color: #9024b2;
  color: red;
  padding: 1px;
}

.placeholder-resize::placeholder {
  font-size: 14px;
}

.spin-animation:hover {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 768px) and (max-width: 870px) {
  .task-info {
    font-size: 12px;
  }
}

.tooltip {
  display: block;
  max-width: 150px;
  width: auto;
}
.tooltip-2 {
  display: block;
  max-width: 250px;
  width: auto;
  background: #fff;
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.bg-quarterly-pink {
  background: #f683ca;
}

.bg-yearly-green {
  background: #32ffc8;
}
.text-quarterly-pink {
  color: #f683ca;
}

.text-yearly-green {
  color: #32ffc8;
}

/* Loader css  */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #9024b2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #9024b2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#loading-page {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  animation: animate 1.2s ease-in-out infinite;
}

@keyframes animate {
  0%,
  10%,
  100% {
    height: 40px;
    width: 40px;
  }
  65% {
    height: 70px;
    width: 70px;
  }
}

#loading-page > span {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  animation: rotate 1.2s linear both infinite;
}

@keyframes rotate {
  0%,
  30% {
    transform: rotate(0);
  }
  65% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

#loading-tasks > span:nth-child(1) {
  top: 0;
  left: 0;
  background: #55d462;
}

#loading-tasks > span:nth-child(2) {
  top: 0;
  right: 0;
  background: #8675e5;
}

#loading-tasks > span:nth-child(3) {
  bottom: 0;
  left: 0;
  background: #d84c77;
}

#loading-tasks > span:nth-child(4) {
  bottom: 0;
  right: 0;
  background: #fd9a3f;
}

/* Pagination css */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #63bdff;
  border-color: #63bdff;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #63bdff;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #63bdff;
  border-color: #63bdff;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #63bdff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

#launch-date-animation {
  animation: typing 7s steps(60) forwards, caret 3s infinite;
}
@keyframes typing {
  to {
    width: 100%;
  }
}
@keyframes caret {
  50% {
    color: transparent;
  }
}
